import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import clientService from '../../services/ClientService';
import { useDispatch } from 'react-redux';
import userService from 'services/UserServoce';
 
 
 
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
 
 
 
export const FamilyDetailsListAll = createAsyncThunk('family-details/list',async (data, { rejectWithValue }) => {
   
    try {
        const response = await userService.getFamilyDetails(data)
        //  console.log("response",response);
        return response.data;
       
    } catch (err) {
       
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
 
 
export const FamilyDetailsSlice = createSlice({
    name: 'FamilyDetails',
    initialState,
    reducers: {
           
        FamilydetailList: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(FamilyDetailsListAll.pending, (state) => {
       
                state.loading = false
            })
            .addCase(FamilyDetailsListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
           
    },
})
 
export const {
    FamilydetailList,
   
   
} = FamilyDetailsSlice.actions
 
export default FamilyDetailsSlice.reducer