import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import userService from 'services/UserServoce';
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
export const EmployeeShowListAll = createAsyncThunk('employee/show',async (data, { rejectWithValue }) => {
    try {
        const response = await userService.showUser(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const EmployeeShowSlice = createSlice({
    name: 'EmployeeShow',
    initialState,
    reducers: {
        EmployeeShowListData: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(EmployeeShowListAll.pending, (state) => {
                state.loading = false
            })
            .addCase(EmployeeShowListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
    },
})
export const {
    EmployeeShowListData,
} = EmployeeShowSlice.actions
export default EmployeeShowSlice.reducer