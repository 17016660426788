import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import FamilyDetails from './slices/familydetailSlice'
import EmployeesAttendence  from './slices/attendance/attendancelist'
import Department from './slices/master/departmentSlice'
import Designation from './slices/master/designationSlice'
import Leave from './slices/master/leaveSlice'
import Document from './slices/master/documentSlice'
import EarningStatement from './slices/master/earningstatementslice'
import Holiday from './slices/master/holidaySlice'
import Employee from './slices/employeelistSlice'
import EmployeeShow from './slices/employeeshowSlice'
import task from './slices/taskSlice'
import Notification from './slices/notificationSlice'
import EmployeesAttendenceShow from './slices/attendance/attendanceshow'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        FamilyDetails ,
        EmployeesAttendence,
        Department,
        Designation,
        Leave,
        Document,
        EarningStatement,
        task,
        Holiday,
        Employee,
        EmployeeShow,
        Notification,
        EmployeesAttendenceShow,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
