import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
export const HolidayListAll = createAsyncThunk('holiday/list',async (data, { rejectWithValue }) => {
    try {
        const response = await masterService.getHoliday(data)
        console.log("response",response);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const HolidaySlice = createSlice({
    name: 'Holiday',
    initialState,
    reducers: {
        HolidayListData: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(HolidayListAll.pending, (state) => {
                state.loading = false
            })
            .addCase(HolidayListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
    },
})
export const {
    HolidayListData,
} = HolidaySlice.actions
export default HolidaySlice.reducer