import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
export const DocumentListAll = createAsyncThunk('document/list',async (data, { rejectWithValue }) => {
    try {
        const response = await masterService.getdocuments(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const DocumentSlice = createSlice({
    name: 'Document',
    initialState,
    reducers: {
        DocumentListData: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(DocumentListAll.pending, (state) => {
                state.loading = false
            })
            .addCase(DocumentListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
    },
})
export const {
    DocumentListData,
} = DocumentSlice.actions
export default DocumentSlice.reducer