import { API_BASE_URL } from "./AppConfig";

const dev = {
  
  // API_ENDPOINT_URL:'http://192.168.0.201:4000/api'
  // API_ENDPOINT_URL:'http://192.168.0.63:8000/api'
  // API_ENDPOINT_URL: 'http://192.168.0.164:1801/api'
  //  API_ENDPOINT_URL: 'https://hris.absax.com/backend/api'
  API_ENDPOINT_URL:'https://hris.crustycodes.com/backend/api'
};
const prod = {
  // API_ENDPOINT_URL:'http://192.168.0.63:8000/api'
  // API_ENDPOINT_URL:'http://192.168.0.201:4000/api'
  // API_ENDPOINT_URL: 'http://192.168.0.164:1801/api'     
  //  API_ENDPOINT_URL: 'https://hris.absax.com/backend/api'
  API_ENDPOINT_URL:'https://hris.crustycodes.com/backend/api'
};
const test = {
  // API_ENDPOINT_URL:'http://192.168.0.63:8000/api'
  // API_ENDPOINT_URL:'http://192.168.0.201:4000/api'
  // API_ENDPOINT_URL: 'http://192.168.0.164:1801/api'
  //  API_ENDPOINT_URL: 'https://hris.absax.com/backend/api'
  API_ENDPOINT_URL:'https://hris.crustycodes.com/backend/api'
};
const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};
export const env = getEnv();
