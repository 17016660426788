import fetch from 'auth/FetchMasterInterceptor'

const taskService = {}

taskService.getTask = function (data) {
  return fetch({
    url: '/task/list',
    method: 'post',
    data:data
  })
}
taskService.addTask = function (data) {
  return fetch({
    url: '/task/create',
    method: 'post',
    data:data
  })
}
taskService.editTask = function (data) {
  return fetch({
    url: '/task/update',
    method: 'post',
    data:data
  })
}
taskService.deleteTask = function (data) {
  return fetch({
    url: '/task/delete',
    method: 'post',
    data:data
  })
}

taskService.searchTask = function (data) {
  return fetch({
    url: '/task/search',
    method: 'post',
    data:data
  })
}

// clientService.getCompany = function (data) {
//   return fetch({
//     url: '/company/list',
//     method: 'post',
//     data:data
//   })
// }
// clientService.addCompany = function (data) {
//   return fetch({
//     url: '/company/create',
//     method: 'post',
//     data:data
//   })
// }
// clientService.editCompany = function (data) {
//   return fetch({
//     url: '/company/edit',
//     method: 'post',
//     data:data
//   })
// }

 
    
export default taskService