import fetch from 'auth/FetchMasterInterceptor'

const masterService = {}

masterService.getDepartment = function (data) {
  return fetch({
    url: '/department/list',
    method: 'post',
    data: data
  })
}
masterService.addDepartment = function (data) {
  return fetch({
    url: '/department/create',
    method: 'post',
    data: data
  })
}
masterService.editDepartment = function (data) {
  return fetch({
    url: '/department/update',
    method: 'post',
    data: data
  })
}
masterService.deleteDepartment = function (data) {
  return fetch({
    url: '/department/delete',
    method: 'post',
    data: data
  })
}
masterService.getDesignation = function (data) {
  return fetch({
    url: '/designation/list',
    method: 'post',
    data: data
  })
}
masterService.addDesignation = function (data) {
  return fetch({
    url: '/designation/create',
    method: 'post',
    data: data
  })
}
masterService.editDesignation = function (data) {
  return fetch({
    url: '/designation/update',
    method: 'post',
    data: data
  })
}
masterService.deleteDesignation = function (data) {
  return fetch({
    url: '/designation/delete',
    method: 'post',
    data: data
  })
}
masterService.getdocuments = function (data) {
  return fetch({
    url: '/document/list',
    method: 'post',
    data: data
  })
}
masterService.addDocuments = function (data) {
  return fetch({
    url: '/document/create',
    method: 'post',
    data: data
  })
}
masterService.editDocument = function (data) {
  return fetch({
    url: '/document/update',
    method: 'post',
    data: data
  })
}
masterService.deleteDocuments = function (data) {
  return fetch({
    url: '/document/delete',
    method: 'post',
    data: data
  })
}
masterService.getexpense = function (data) {
  return fetch({
    url: '/expenses-type/list',
    method: 'post',
    data: data
  })
}
masterService.editExpense = function (data) {
  return fetch({
    url: '/expenses-type/update',
    method: 'post',
    data: data
  })
}
masterService.addExpense = function (data) {
  return fetch({
    url: '/expenses-type/create',
    method: 'post',
    data: data
  })
}
masterService.deleteExpense = function (data) {
  return fetch({
    url: '/expenses-type/delete',
    method: 'post',
    data: data
  })
}
masterService.getassets = function (data) {
  return fetch({
    url: '/asset/list',
    method: 'post',
    data: data
  })
}
masterService.editassets = function (data) {
  return fetch({
    url: '/asset/update',
    method: 'post',
    data: data
  })
}
masterService.addassets = function (data) {
  return fetch({
    url: '/asset/create',
    method: 'post',
    data: data
  })
}
masterService.deleteAssets= function (data) {
  return fetch({
    url: '/asset/delete',
    method: 'post',
    data: data
  })
}


masterService.getFrequency = function (data) {
  return fetch({
    url: '/frequency/list',
    method: 'post',
    data: data
  })
}
masterService.addFrequency = function (data) {
  return fetch({
    url: '/frequency/create',
    method: 'post',
    data: data
  })
}
masterService.editFrequency = function (data) {
  return fetch({
    url: '/frequency/edit',
    method: 'post',
    data: data
  })
}
masterService.deleteFrequency = function (data) {
  return fetch({
    url: '/frequency/delete',
    method: 'post',
    data: data
  })
}    // change 7 
masterService.getbankdetail = function (data) {

  return fetch({
    url: '/bank-details/list',
    method: 'post',
    data: data
  })
}
masterService.editbankdetail = function (data) {

  return fetch({
    url: '/bank-details/storeUpdate',
    method: 'post',
    data: data
  })
}
masterService.addbankdetail = function (data) {

  return fetch({
    url: '/bank-details/storeUpdate',
    method: 'post',
    data: data
  })
}
masterService.deletebankdetail = function (data) {

  return fetch({
    url: '/bank-details/delete',
    method: 'post',
    data: data
  })
}
masterService.getleave = function (data) {

  return fetch({
    url: '/leave/list',
    method: 'post',
    data: data
  })
}
masterService.editLeave = function (data) {
  return fetch({
    url: '/leave/update',
    method: 'post',
    data: data
  })
}
masterService.addLeave = function (data) {
  return fetch({
    url: '/leave/create',
    method: 'post',
    data: data
  })
}
masterService.deleteleave = function (data) {
  return fetch({
    url: '/leave/delete',
    method: 'post',
    data: data
  })
}
masterService.Userleave = function (data) {
  return fetch({
    url: '/user-leave/getuserleaves',
    method: 'post',
    data: data
  })
}

masterService.getdocument = function (data) {

  return fetch({
    url: '/userdocument/create',
    method: 'post',
    data: data
  })
}
masterService.getearningstatement = function (data) {

  return fetch({
    url: '/type-valuation/list',
    method: 'post',
    data: data
  })
}
masterService.showearningstatement = function (data) {

  return fetch({
    url: '/type-rate/show',
    method: 'post',
    data: data
  })
}
masterService.addearningstatement = function (data) {

  return fetch({
    url: '/type-valuation/create',
    method: 'post',
    data: data
  })
}
masterService.editearningstatement = function (data) {

  return fetch({
    url: '/type-valuation/update',
    method: 'post',
    data: data
  })
}
masterService.deleteearningstatement = function (data) {

  return fetch({
    url: '/type-valuation/delete',
    method: 'post',
    data: data
  })
}
masterService.getuserdocuments = function (data) {

  return fetch({
    url: '/employee/documentList',
    method: 'post',
    data: data
  })
}
masterService.getasset = function (data) {

  return fetch({
    url: '/userasset/list',
    method: 'post',
    data: data
  })
}
masterService.getuserasset = function (data) {

  return fetch({
    url: '/user-asset/list',
    method: 'post',
    data: data
  })
}
masterService.adduserasset = function (data) {

  return fetch({
    url: '/user-asset/create',
    method: 'post',
    data: data
  })
}
masterService.edituserasset = function (data) {

  return fetch({
    url: '/user-asset/update',
    method: 'post',
    data: data
  })
}
masterService.deleteuserasset = function (data) {

  return fetch({
    url: '/user-asset/delete',
    method: 'post',
    data: data
  })
}

masterService.gettickettype = function (data) {

  return fetch({
    url: '/ticket-type/list',
    method: 'post',
    data: data
  })
}
masterService.addtickettype = function (data) {

  return fetch({
    url: '/ticket-type/create',
    method: 'post',
    data: data
  })
}
masterService.Edittickettype = function (data) {

  return fetch({
    url: '/ticket-type/update',
    method: 'post',
    data: data
  })
}
masterService.deletetickettype = function (data) {

  return fetch({
    url: '/ticket-type/delete',
    method: 'post',
    data: data
  })
}
masterService.getpayslip = function (data) {

  return fetch({
    url: '/payslip/list',
    method: 'post',
    data: data
  })
}
masterService.getuserpayslip = function (data) {

  return fetch({
    url: '/payslip/list',
    method: 'post',
    data: data
  })
}
masterService.getHoliday = function (data) {
  return fetch({
    url: '/holiday/list',
    method: 'post',
    data: data
  })
}
masterService.addHoliday = function (data) {
  return fetch({
    url: '/holiday/create',
    method: 'post',
    data: data
  })
}
masterService.editHoliday = function (data) {
  return fetch({
    url: '/holiday/update',
    method: 'post',
    data: data
  })
}
masterService.deleteHoliday = function (data) {
  return fetch({
    url: '/holiday/delete',
    method: 'post',
    data: data
  })
}
masterService.getRole = function (data) {

  return fetch({
    url: '/role/list',
    method: 'post',
    data: data
  })
}

masterService.editRole = function (data) {

  return fetch({
    url: '/role/update',
    method: 'post',
    data: data
  })
}

masterService.addRole = function (data) {

  return fetch({
    url: '/role/create',
    method: 'post',
    data: data
  })
}

masterService.deleteRole = function (data) {

  return fetch({
    url: '/role/delete',
    method: 'post',
    data: data
  })
}


masterService.getPermission = function (data) {

  return fetch({
    url: '/permission/list',
    method: 'post',
    data: data
  })
}

masterService.changePassword = function (data) {

  return fetch({
    url: '/auth/change-password',
    method: 'post',
    data: data
  })
}
masterService.assignPermission = function (data) {

  return fetch({
    url: '/role/assign',
    method: 'post',
    data: data
  })
}

export default masterService
