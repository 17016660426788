import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import masterService from 'services/MasterService';
export const initialState = {
    loading: false,
    message: '',
    list:null,
}
export const DesignationListAll = createAsyncThunk('designation/list',async (data, { rejectWithValue }) => {
    try {
        const response = await masterService.getDesignation(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})
export const DesignationSlice = createSlice({
    name: 'Designation',
    initialState,
    reducers: {
        DesignationListData: (state, action) => {
            state.loading = true
            state.list = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(DesignationListAll.pending, (state) => {
                state.loading = false
            })
            .addCase(DesignationListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
    },
})
export const {
    DesignationListData,
} = DesignationSlice.actions
export default DesignationSlice.reducer